import { soccer } from "../sports";
import moment from "moment";
import numeral from "numeral";
require("numeral/locales/en-gb");

const shirts = {
  'Ajax': {
      name: 'Ajax',
      player: '/img/uwcl/shirts/Ajax_200px.png',
      gk: '/img/uwcl/shirts/Ajax_GK.png',
  },
  'Barcelona': {
      name: 'Barcelona',
      player: '/img/uwcl/shirts/BAR200.png',
      gk: '/img/uwcl/shirts/BAR200_GK.png',
  },
  'Bayern Munich': {
      name: 'Bayern Munich',
      player: '/img/uwcl/shirts/BAY200.png',
      gk: '/img/uwcl/shirts/BAY200_GK.png',
  },
  'Benfica': {
      name: 'Benfica',
      player: '/img/uwcl/shirts/Benfica_200px.png',
      gk: '/img/uwcl/shirts/Benfica_GK.png',
  },
  'Brann': {
      name: 'Brann',
      player: '/img/uwcl/shirts/Brann_200px.png',
      gk: '/img/uwcl/shirts/Brann_GK.png',
  },
  'Chelsea': {
      name: 'Chelsea',
      player: '/img/uwcl/shirts/CHE200.png',
      gk: '/img/uwcl/shirts/CHE200_GK.png',
  },
  'Eintracht Frankfurt': {
      name: 'Eintracht Frankfurt',
      player: '/img/uwcl/shirts/Frankfurt_200px.png',
      gk: '/img/uwcl/shirts/Frankfurt_GK_200px.png',
  },
  'Häcken': {
      name: 'Häcken',
      player: '/img/uwcl/shirts/Hacken_200px.png',
      gk: '/img/uwcl/shirts/Hacken_GK.png',
  },
  'Lyon': {
      name: 'Lyon',
      player: '/img/uwcl/shirts/LYO200.png',
      gk: '/img/uwcl/shirts/LYO200_GK.png',
  },
  'Paris FC': {
      name: 'Paris FC',
      player: '/img/uwcl/shirts/Paris.png',
      gk: '/img/uwcl/shirts/Paris_GK.png',
  },
  'Paris Saint-Germain': {
      name: 'Paris Saint-Germain',
      player: '/img/uwcl/shirts/PSG_200px.png',
      gk: '/img/uwcl/shirts/PSG_GK_200px.png',
  },
  'Real Madrid': {
      name: 'Real Madrid',
      player: '/img/uwcl/shirts/RMA200.png',
      gk: '/img/uwcl/shirts/RMA200_GK.png',
  },
  'Roma': {
      name: 'Roma',
      player: '/img/uwcl/shirts/ROM200.png',
      gk: '/img/uwcl/shirts/ROM200_GK.png',
  },
  'Rosengård': {
      name: 'Rosengård',
      player: '/img/uwcl/shirts/Rosengard_200px.png',
      gk: '/img/uwcl/shirts/Rosengard_GK.png',
  },
  'Slavia Prague': {
      name: 'Slavia Prague',
      player: '/img/uwcl/shirts/Slavia_Praha_200px.png',
      gk: '/img/uwcl/shirts/Slavia_Praha_GK_200px.png',
  },
  'St. Pölten': {
      name: 'St. Pölten',
      player: '/img/uwcl/shirts/STP200.png',
      gk: '/img/uwcl/shirts/STP200_GK.png',
  },
  'Arsenal': {
      name: 'Arsenal',
      player: '/img/uwcl/shirts/ARS200.png',
      gk: '/img/uwcl/shirts/ARS200_GK.png',
  },
  'Manchester City': {
      name: 'Manchester City',
      player: '/img/uwcl/shirts/MCI200.png',
      gk: '/img/uwcl/shirts/MCI200_GK.png',
  },
  'Celtic': {
      name: 'Celtic',
      player: '/img/uwcl/shirts/CEL200.png',
      gk: '/img/uwcl/shirts/CEL200_GK.png',
  },
  'Galatasaray': {
      name: 'Galatasaray',
      player: '/img/uwcl/shirts/GAL200.png',
      gk: '/img/uwcl/shirts/GAL200_GK.png',
  },
  'Hammarby': {
      name: 'Hammarby',
      player: '/img/uwcl/shirts/HAM200.png',
      gk: '/img/uwcl/shirts/HAM200_GK.png',
  },
  'Juventus': {
      name: 'Juventus',
      player: '/img/uwcl/shirts/JUV200.png',
      gk: '/img/uwcl/shirts/JUV200_GK.png',
  },
  'Twente': {
      name: 'Twente',
      player: '/img/uwcl/shirts/TWE200.png',
      gk: '/img/uwcl/shirts/TWE200_GK.png',
  },
  'Vålerenga': {
      name: 'Vålerenga',
      player: '/img/uwcl/shirts/VAL200.png',
      gk: '/img/uwcl/shirts/VAL200_GK.png',
  },
  'Wolfsburg': {
      name: 'Wolfsburg',
      player: '/img/uwcl/shirts/WOL200.png',
      gk: '/img/uwcl/shirts/WOL200_GK.png',
  },
  "PNA": {
    name: "Player Not Available",
    player: "/img/uwcl/shirts/PNA.png",
    gk: "/img/uwcl/shirts/PNAGK.png"
  },
  "Player Not Available": {
    name: "Player Not Available",
    player: "/img/wsl/shirts/PNA.png",
    gk: "/img/wsl/shirts/PNAGK.png"
  }
};

const rounds = [
  {
    name: "Round1",
    round: "round-1",
    opens: moment("2020-01-03 12:00:00+00"),
    closes: moment("2024-10-08 16:45:00+00")
  },
  {
    name: "Round2",
    round: "round-2",
    opens: moment("2024-10-10 16:45:00+00"),
    closes: moment("2024-10-16 16:45:00+00")
  },
  {
    name: "Round3",
    round: "round-3",
    opens: moment("2024-10-18 16:45:00+00"),
    closes: moment("2024-11-12 17:45:00+00")
  },
  {
    name: "Round4",
    round: "round-4",
    opens: moment("2024-11-14 17:45:00+00"),
    closes: moment("2024-11-20 17:45:00+00")
  },
  {
    name: "Round5",
    round: "round-5",
    opens: moment("2024-11-22 17:45:00+00"),
    closes: moment("2024-12-11 17:45:00+00")
  },
  {
    name: "Round6",
    round: "round-6",
    opens: moment("2024-12-13 17:45:00+00"),
    closes: moment("2024-12-17 17:45:00+00")
  },
  {
    name: "Round7",
    round: "round-7",
    opens: moment("2024-12-19 17:45:00+00"),
    closes: moment("2025-03-18 17:45:00+00")
  },
  {
    name: "Round8",
    round: "round-8",
    opens: moment("2025-03-20 17:45:00+00"),
    closes: moment("2025-03-26 17:45:00+00")
  }
];

const init = () => {
  numeral.locale("en-gb");
  numeral.defaultFormat("$0,0");
};

export const uwcl = {
  shortName: "uwcl",
  competition: "uwcl",
  name: "UEFA Womens Champions League",
  sport: soccer,
  totalBudget: 8500000,
  minPlayerPrice: 100000,
  maxPlayerPrice: 1000000,
  perClubLimit: 5,
  hashtag: "ShePlaysUWCL",
  shirts,
  init,
  rounds,
  freeTransfers: 5,
  transferCost: 5,
  teams: shirts,
  season: "2024"
};

