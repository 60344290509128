export default [
  require("./playerProfiles/players/eda-karatas"),
  require("./playerProfiles/players/jazmin-jackmon"),
  require("./playerProfiles/players/alessia-capelletti"),
  require("./playerProfiles/players/sophie-proost"),
  require("./playerProfiles/players/martina-viesti"),
  require("./playerProfiles/players/julie-blakstad"),
  require("./playerProfiles/players/valentina-bergamaschi"),
  require("./playerProfiles/players/catalina-usme"),
  require("./playerProfiles/players/sagre-öztürk"),
  require("./playerProfiles/players/lina-klech"),
  require("./playerProfiles/players/fenna-kalma"),
  require("./playerProfiles/players/maeline-mendy"),
  require("./playerProfiles/players/maelys-mpome"),
  require("./playerProfiles/players/melchie-dumornay"),
  require("./playerProfiles/players/mawa-sesay"),
  require("./playerProfiles/players/sofie-svava"),
  require("./playerProfiles/players/leonarda-balog"),
  require("./playerProfiles/players/alba-redondo"),
  require("./playerProfiles/players/sofia-huerta"),
  require("./playerProfiles/players/mary-fowler"),
  require("./playerProfiles/players/elise-thorsnes"),
  require("./playerProfiles/players/martina-cocino"),
  require("./playerProfiles/players/viola-calligaris"),
  require("./playerProfiles/players/greta-bellagente"),
  require("./playerProfiles/players/saoirse-noonan"),
  require("./playerProfiles/players/caroline-møller"),
  require("./playerProfiles/players/eva-schatzer"),
  require("./playerProfiles/players/teresa-abelleira"),
  require("./playerProfiles/players/leah-williamson"),
  require("./playerProfiles/players/magdalena-eriksson"),
  require("./playerProfiles/players/kerolin"),
  require("./playerProfiles/players/laura-coombs"),
  require("./playerProfiles/players/maría-mendez"),
  require("./playerProfiles/players/sarah-zadrazil"),
  require("./playerProfiles/players/alayah-pilgrim"),
  require("./playerProfiles/players/caroline-weir"),
  require("./playerProfiles/players/martina-lenzini"),
  require("./playerProfiles/players/kim-everearts"),
  require("./playerProfiles/players/smilla-vallotto"),
  require("./playerProfiles/players/amel-majri"),
  require("./playerProfiles/players/fridolina-rolfö"),
  require("./playerProfiles/players/weronika-zawistowska"),
  require("./playerProfiles/players/amanda-ilestedt"),
  require("./playerProfiles/players/carla-carrillo"),
  require("./playerProfiles/players/sandy-baltimore"),
  require("./playerProfiles/players/saki-kumagai"),
  require("./playerProfiles/players/sydney-schertenleib"),
  require("./playerProfiles/players/hannah-hampton"),
  require("./playerProfiles/players/olaug-tvedten"),
  require("./playerProfiles/players/ellie-carpenter"),
  require("./playerProfiles/players/caitlin-dijkstra"),
  require("./playerProfiles/players/shelia-garcía"),
  require("./playerProfiles/players/thea-bjelde"),
  require("./playerProfiles/players/arzu-karabulut"),
  require("./playerProfiles/players/ada-hegerberg"),
  require("./playerProfiles/players/nikée-van-dijk"),
  require("./playerProfiles/players/aldiana-amuchie"),
  require("./playerProfiles/players/victoria-pelova"),
  require("./playerProfiles/players/lynn-wilms"),
  require("./playerProfiles/players/maría-león"),
  require("./playerProfiles/players/maria-luisa-grohs"),
  require("./playerProfiles/players/caitlin-hayes"),
  require("./playerProfiles/players/joelle-wedemeyer"),
  require("./playerProfiles/players/charlotte-hulst"),
  require("./playerProfiles/players/leila-ouahabi"),
  require("./playerProfiles/players/gamze-yaman"),
  require("./playerProfiles/players/hanna-bennison"),
  require("./playerProfiles/players/jess-park"),
  require("./playerProfiles/players/lucy-bronze"),
  require("./playerProfiles/players/colette-cavanagh"),
  require("./playerProfiles/players/jennifer-klein"),
  require("./playerProfiles/players/tara-o'hanlon"),
  require("./playerProfiles/players/chiara-d'angelo"),
  require("./playerProfiles/players/eve-perisset"),
  require("./playerProfiles/players/melike-pekel"),
  require("./playerProfiles/players/elsa-pelgander"),
  require("./playerProfiles/players/emma-mustafic"),
  require("./playerProfiles/players/mayra-ramirez"),
  require("./playerProfiles/players/ellie-roebuck"),
  require("./playerProfiles/players/carina-schlüter"),
  require("./playerProfiles/players/aniek-nouwen"),
  require("./playerProfiles/players/linda-sembrant"),
  require("./playerProfiles/players/tainara"),
  require("./playerProfiles/players/olga-carmona"),
  require("./playerProfiles/players/ashley-lawrence"),
  require("./playerProfiles/players/suus-verdaasdonk"),
  require("./playerProfiles/players/sofia-cantore"),
  require("./playerProfiles/players/natalie-ross"),
  require("./playerProfiles/players/lindsey-thomas"),
  require("./playerProfiles/players/barbara-bonansea"),
  require("./playerProfiles/players/hana-kerner"),
  require("./playerProfiles/players/alejandra-bernabe"),
  require("./playerProfiles/players/freya-godfrey"),
  require("./playerProfiles/players/cathinka-tandberg"),
  require("./playerProfiles/players/danique-van-ginkel"),
  require("./playerProfiles/players/arianna-caruso"),
  require("./playerProfiles/players/janni-thomsen"),
  require("./playerProfiles/players/kathrine-møller-kühl"),
  require("./playerProfiles/players/ellen-wangerhein"),
  require("./playerProfiles/players/izabela-križaj"),
  require("./playerProfiles/players/alice-corelli"),
  require("./playerProfiles/players/christine-endler"),
  require("./playerProfiles/players/kika-nazareth"),
  require("./playerProfiles/players/sara-hørte"),
  require("./playerProfiles/players/vilde-hasund"),
  require("./playerProfiles/players/mariona-caldentey"),
  require("./playerProfiles/players/pernille-harder"),
  require("./playerProfiles/players/millie-bright"),
  require("./playerProfiles/players/ana-guzmán"),
  require("./playerProfiles/players/signe-carstens"),
  require("./playerProfiles/players/ecem-cümert"),
  require("./playerProfiles/players/camelia-ceasar"),
  require("./playerProfiles/players/chloe-logan"),
  require("./playerProfiles/players/chloe-kelly"),
  require("./playerProfiles/players/lisa-schmitz"),
  require("./playerProfiles/players/tommine-enger"),
  require("./playerProfiles/players/noemi"),
  require("./playerProfiles/players/tea-vračević"),
  require("./playerProfiles/players/sara-gama"),
  require("./playerProfiles/players/georgia-stanway"),
  require("./playerProfiles/players/mateja-zver"),
  require("./playerProfiles/players/linn-vickius"),
  require("./playerProfiles/players/isabella-kresche"),
  require("./playerProfiles/players/maria-mcaneny"),
  require("./playerProfiles/players/emilie-haavi"),
  require("./playerProfiles/players/inès-benyahia"),
  require("./playerProfiles/players/arianna-gallina"),
  require("./playerProfiles/players/agnes-beever-jones"),
  require("./playerProfiles/players/filippa-angeldal"),
  require("./playerProfiles/players/josephine-frigge"),
  require("./playerProfiles/players/jill-roord"),
  require("./playerProfiles/players/martina-rosucci"),
  require("./playerProfiles/players/karla-brinkmann"),
  require("./playerProfiles/players/laia-codina"),
  require("./playerProfiles/players/alessia-russo"),
  require("./playerProfiles/players/azzurra-gallo"),
  require("./playerProfiles/players/lineth-beerensteyn"),
  require("./playerProfiles/players/khiara-keating"),
  require("./playerProfiles/players/ella-touon"),
  require("./playerProfiles/players/sara-däbritz"),
  require("./playerProfiles/players/valentina-piccardi"),
  require("./playerProfiles/players/emilie-joramo"),
  require("./playerProfiles/players/julia-zigiotti-olme"),
  require("./playerProfiles/players/andrea-stašková"),
  require("./playerProfiles/players/doris-petz"),
  require("./playerProfiles/players/michelle-umbrich"),
  require("./playerProfiles/players/leonie-vliek"),
  require("./playerProfiles/players/ylinn-tennebø"),
  require("./playerProfiles/players/martina-fernández"),
  require("./playerProfiles/players/eva-oude-elberink"),
  require("./playerProfiles/players/lauren-james"),
  require("./playerProfiles/players/laura-sieger"),
  require("./playerProfiles/players/alicia-de-la-cuerda"),
  require("./playerProfiles/players/rosa-kafaji"),
  require("./playerProfiles/players/sædís-hedarsdóttir"),
  require("./playerProfiles/players/luana-munoz"),
  require("./playerProfiles/players/jill-diekman"),
  require("./playerProfiles/players/rebecca-knaak"),
  require("./playerProfiles/players/oriane-jean-françois"),
  require("./playerProfiles/players/sophie-ingle"),
  require("./playerProfiles/players/giulia-gwinn"),
  require("./playerProfiles/players/anneke-borbe"),
  require("./playerProfiles/players/sam-kerr"),
  require("./playerProfiles/players/tuva-espås"),
  require("./playerProfiles/players/mathilde-carstens"),
  require("./playerProfiles/players/asato-miyagawa"),
  require("./playerProfiles/players/sanne-troelsgaard"),
  require("./playerProfiles/players/kysha-sylla"),
  require("./playerProfiles/players/melissa-abiral"),
  require("./playerProfiles/players/elma-junttila-nelhage"),
  require("./playerProfiles/players/judit-pujols"),
  require("./playerProfiles/players/eleonora-ferraresi"),
  require("./playerProfiles/players/elena-pizzuti"),
  require("./playerProfiles/players/lucia-di-guglielmo"),
  require("./playerProfiles/players/laia-aleixandri"),
  require("./playerProfiles/players/alice-marques"),
  require("./playerProfiles/players/anna-knol"),
  require("./playerProfiles/players/kyra-cooney-cross"),
  require("./playerProfiles/players/laila-harbert"),
  require("./playerProfiles/players/franziska-kett"),
  require("./playerProfiles/players/wendie-renard"),
  require("./playerProfiles/players/giulia-dragoni"),
  require("./playerProfiles/players/mia-fishel"),
  require("./playerProfiles/players/maëlle-lakrar"),
  require("./playerProfiles/players/ona-batlle"),
  require("./playerProfiles/players/laura-wienroither"),
  require("./playerProfiles/players/handan-kurga"),
  require("./playerProfiles/players/bella-andersson"),
  require("./playerProfiles/players/ewa-pajor"),
  require("./playerProfiles/players/porto"),
  require("./playerProfiles/players/caitlin-foord"),
  require("./playerProfiles/players/stine-brekken"),
  require("./playerProfiles/players/justine-kielland"),
  require("./playerProfiles/players/alexia-putellas"),
  require("./playerProfiles/players/lisa-rogers"),
  require("./playerProfiles/players/jennifer-smith"),
  require("./playerProfiles/players/sydney-lohmann"),
  require("./playerProfiles/players/aoba-fujino"),
  require("./playerProfiles/players/anna-jøsendal"),
  require("./playerProfiles/players/jovana-damnjanović"),
  require("./playerProfiles/players/sally-nylén"),
  require("./playerProfiles/players/olivia-clark"),
  require("./playerProfiles/players/kim-little"),
  require("./playerProfiles/players/daniela-galic"),
  require("./playerProfiles/players/clare-goldie"),
  require("./playerProfiles/players/giulia-mazzocchi"),
  require("./playerProfiles/players/linda-dallmann"),
  require("./playerProfiles/players/wieke-kaptein"),
  require("./playerProfiles/players/sjoeke-nusken"),
  require("./playerProfiles/players/emma-kullberg"),
  require("./playerProfiles/players/aitana-bonmatí"),
  require("./playerProfiles/players/katherine-loferski"),
  require("./playerProfiles/players/lena-lattwein"),
  require("./playerProfiles/players/alieke-tuin"),
  require("./playerProfiles/players/sarai-linder"),
  require("./playerProfiles/players/selma-bacha"),
  require("./playerProfiles/players/daniëlle-de-jong"),
  require("./playerProfiles/players/lysianne-proulx"),
  require("./playerProfiles/players/lea-schüller"),
  require("./playerProfiles/players/benan-altintas"),
  require("./playerProfiles/players/murphy-agnew"),
  require("./playerProfiles/players/arzu-akkurt"),
  require("./playerProfiles/players/sarah-mattner"),
  require("./playerProfiles/players/giada-greggi"),
  require("./playerProfiles/players/yasmim"),
  require("./playerProfiles/players/ronja-foss-arnesen"),
  require("./playerProfiles/players/katherina-naschenweng"),
  require("./playerProfiles/players/feerine-belhadj"),
  require("./playerProfiles/players/michaela-dominique-kovacs"),
  require("./playerProfiles/players/eva-navarro"),
  require("./playerProfiles/players/alice-kristina-karlsson"),
  require("./playerProfiles/players/oihane-hernández"),
  require("./playerProfiles/players/maika-hamano"),
  require("./playerProfiles/players/klara-bühl"),
  require("./playerProfiles/players/giulia-galli"),
  require("./playerProfiles/players/alanna-kennedy"),
  require("./playerProfiles/players/kristina-bakarandze"),
  require("./playerProfiles/players/kadeisha-buchanan"),
  require("./playerProfiles/players/ariana-arias"),
  require("./playerProfiles/players/kamila-dubcová"),
  require("./playerProfiles/players/signe-bruun"),
  require("./playerProfiles/players/ayaka-yamashita"),
  require("./playerProfiles/players/alexandra-popp"),
  require("./playerProfiles/players/iselin-sandnes-olsen"),
  require("./playerProfiles/players/omotara-junaid"),
  require("./playerProfiles/players/salma-paralluelo"),
  require("./playerProfiles/players/naomie-feller"),
  require("./playerProfiles/players/kathrin-hendrich"),
  require("./playerProfiles/players/shannon-mcgregor"),
  require("./playerProfiles/players/suzu-amano"),
  require("./playerProfiles/players/benedetta-glionna"),
  require("./playerProfiles/players/linda-caicedo"),
  require("./playerProfiles/players/amanda-andradóttir"),
  require("./playerProfiles/players/mária-mikolajová"),
  require("./playerProfiles/players/fiene-bussman"),
  require("./playerProfiles/players/tilde-lindwall"),
  require("./playerProfiles/players/vanessa-gilles"),
  require("./playerProfiles/players/liliana-merolla"),
  require("./playerProfiles/players/emma-louise-lawton"),
  require("./playerProfiles/players/sophie-hillebrand"),
  require("./playerProfiles/players/vivianne-miedema"),
  require("./playerProfiles/players/julie-swierot"),
  require("./playerProfiles/players/nathalie-björn"),
  require("./playerProfiles/players/carolin-simon"),
  require("./playerProfiles/players/smilla-holmberg"),
  require("./playerProfiles/players/morgan-cross"),
  require("./playerProfiles/players/keira-walsh"),
  require("./playerProfiles/players/alara-şehitler"),
  require("./playerProfiles/players/pia-grinde-hansen"),
  require("./playerProfiles/players/lia-walti"),
  require("./playerProfiles/players/hawa-cissoko"),
  require("./playerProfiles/players/luca-papp"),
  require("./playerProfiles/players/daphne-van-domselaar"),
  require("./playerProfiles/players/melanie-leupolz"),
  require("./playerProfiles/players/pauline-peyraud-magnin"),
  require("./playerProfiles/players/müge-kandur"),
  require("./playerProfiles/players/stina-blackstenius"),
  require("./playerProfiles/players/asia-bragonzi"),
  require("./playerProfiles/players/eugénie-le-sommer"),
  require("./playerProfiles/players/katie-mccabe"),
  require("./playerProfiles/players/manuela-zinsberger"),
  require("./playerProfiles/players/merle-frohms"),
  require("./playerProfiles/players/marta-pandini"),
  require("./playerProfiles/players/claudia-wenger"),
  require("./playerProfiles/players/patri-guijarro"),
  require("./playerProfiles/players/roos-huitzing"),
  require("./playerProfiles/players/anna-johanning"),
  require("./playerProfiles/players/tarciane"),
  require("./playerProfiles/players/chiara-beccari"),
  require("./playerProfiles/players/samantha-kerr"),
  require("./playerProfiles/players/estelle-cascarino"),
  require("./playerProfiles/players/rocío-gálvez"),
  require("./playerProfiles/players/laura-benkarth"),
  require("./playerProfiles/players/vivien-endemann"),
  require("./playerProfiles/players/moa-edrud"),
  require("./playerProfiles/players/kayleigh-van-dooren"),
  require("./playerProfiles/players/eve-o'carroll"),
  require("./playerProfiles/players/lindsey-horan"),
  require("./playerProfiles/players/ebru-topçu"),
  require("./playerProfiles/players/blessing-demehin"),
  require("./playerProfiles/players/gemma-font"),
  require("./playerProfiles/players/daniëlle-van-de-donk"),
  require("./playerProfiles/players/eva-nyström"),
  require("./playerProfiles/players/maud-koster"),
  require("./playerProfiles/players/damaris-egurrola"),
  require("./playerProfiles/players/jaimy-ravensbergen"),
  require("./playerProfiles/players/kadidiatou-diani"),
  require("./playerProfiles/players/rose-ivens"),
  require("./playerProfiles/players/rosanna-ventrigli"),
  require("./playerProfiles/players/rebecka-blomqvist"),
  require("./playerProfiles/players/valentina-giacinti"),
  require("./playerProfiles/players/vicky-lópez"),
  require("./playerProfiles/players/misa-rodríguez"),
  require("./playerProfiles/players/emma-westin"),
  require("./playerProfiles/players/bruna-costa"),
  require("./playerProfiles/players/lauren-hemp"),
  require("./playerProfiles/players/rebecca-spencer"),
  require("./playerProfiles/players/mylene-chavas"),
  require("./playerProfiles/players/laura-blindkilde-brown"),
  require("./playerProfiles/players/thea-sørbo"),
  require("./playerProfiles/players/tuva-hansen"),
  require("./playerProfiles/players/naomi-williams"),
  require("./playerProfiles/players/cristiana-girelli"),
  require("./playerProfiles/players/alisha-lehmann"),
  require("./playerProfiles/players/katie-reid"),
  require("./playerProfiles/players/tabitha-chawinga"),
  require("./playerProfiles/players/lotte-wubben-moy"),
  require("./playerProfiles/players/karina-sævik"),
  require("./playerProfiles/players/naomi-girma"),
  require("./playerProfiles/players/jenna-nighswonger"),
  require("./playerProfiles/players/frederikke-thøgersen"),
  require("./playerProfiles/players/verena-hanshaw"),
  require("./playerProfiles/players/emma-stølen-godø"),
  require("./playerProfiles/players/glódís-viggósdóttir"),
  require("./playerProfiles/players/selma-pettersen"),
  require("./playerProfiles/players/erin-cuthbert"),
  require("./playerProfiles/players/sandie-toletti"),
  require("./playerProfiles/players/lena-oberdorf"),
  require("./playerProfiles/players/anna-wellmann"),
  require("./playerProfiles/players/katie-cox"),
  require("./playerProfiles/players/cata-coll"),
  require("./playerProfiles/players/jonna-andersson"),
  require("./playerProfiles/players/naomi-layzell"),
  require("./playerProfiles/players/ella-peddemors"),
  require("./playerProfiles/players/vicki-becho"),
  require("./playerProfiles/players/flourish-sabastin"),
  require("./playerProfiles/players/rita-schumacher"),
  require("./playerProfiles/players/kerstin-casparij"),
  require("./playerProfiles/players/tea-krznarić"),
  require("./playerProfiles/players/valentina-mädl"),
  require("./playerProfiles/players/lisa-boattin"),
  require("./playerProfiles/players/codie-thomas"),
  require("./playerProfiles/players/diallo-hapsatou-malado"),
  require("./playerProfiles/players/olivie-lukášová"),
  require("./playerProfiles/players/clàudia-pina"),
  require("./playerProfiles/players/athenea-del-castillo"),
  require("./playerProfiles/players/emine-esen"),
  require("./playerProfiles/players/amy-richardson"),
  require("./playerProfiles/players/amalie-vangsgaard"),
  require("./playerProfiles/players/johanna-rytting-kaneryd"),
  require("./playerProfiles/players/steph-catley"),
  require("./playerProfiles/players/viola-sossai"),
  require("./playerProfiles/players/jule-brand"),
  require("./playerProfiles/players/chantal-hagel"),
  require("./playerProfiles/players/elena-linari"),
  require("./playerProfiles/players/sara-terlizzi"),
  require("./playerProfiles/players/claudia-de-la-cuerda"),
  require("./playerProfiles/players/maja-madon"),
  require("./playerProfiles/players/kiki-vissers"),
  require("./playerProfiles/players/ismigül-yalçiner"),
  require("./playerProfiles/players/vivienne-lia"),
  require("./playerProfiles/players/nazlican-parlak"),
  require("./playerProfiles/players/wassa-sangaré"),
  require("./playerProfiles/players/katie-startup"),
  require("./playerProfiles/players/janina-minge"),
  require("./playerProfiles/players/antonia"),
  require("./playerProfiles/players/andrea-glibo"),
  require("./playerProfiles/players/yui-hasegawa"),
  require("./playerProfiles/players/amelie-kandlhofer"),
  require("./playerProfiles/players/lily-murphy"),
  require("./playerProfiles/players/moeka-minami"),
  require("./playerProfiles/players/nuria-rábano"),
  require("./playerProfiles/players/momoko-tanikawa"),
  require("./playerProfiles/players/paulina-krumbiegel"),
  require("./playerProfiles/players/evelyne-viens"),
  require("./playerProfiles/players/jana-fernández"),
  require("./playerProfiles/players/ingrid-syrstad-engen"),
  require("./playerProfiles/players/liana-joseph"),
  require("./playerProfiles/players/esmee-brugts"),
  require("./playerProfiles/players/lina-hurtig"),
  require("./playerProfiles/players/sophie-te-brake"),
  require("./playerProfiles/players/stina-lennartsson"),
  require("./playerProfiles/players/lucy-ashworth-clifford"),
  require("./playerProfiles/players/emily-fox"),
  require("./playerProfiles/players/kelly-clark"),
  require("./playerProfiles/players/ellen-gibson"),
  require("./playerProfiles/players/laura-gloning"),
  require("./playerProfiles/players/caroline-graham-hansen"),
  require("./playerProfiles/players/marta-torrejón"),
  require("./playerProfiles/players/guro-reiten"),
  require("./playerProfiles/players/beth-mead"),
  require("./playerProfiles/players/kelsey-daugherty"),
  require("./playerProfiles/players/manuela-giugliano"),
  require("./playerProfiles/players/lieske-carleer"),
  require("./playerProfiles/players/catarina-macario"),
  require("./playerProfiles/players/alice-sombath"),
  require("./playerProfiles/players/amy-gallacher"),
  require("./playerProfiles/players/anna-tamminen"),
  require("./playerProfiles/players/thiril-erichsen"),
  require("./playerProfiles/players/merel-bormans"),
  require("./playerProfiles/players/ena-mahmutovic"),
  require("./playerProfiles/players/alex-greenwood"),
  require("./playerProfiles/players/irene-paredes"),
  require("./playerProfiles/players/zecira-musovic"),
  require("./playerProfiles/players/dzenifer-marozsán"),
  require("./playerProfiles/players/celya-barclais"),
  require("./playerProfiles/players/svenja-huth"),
  require("./playerProfiles/players/melanie-brunnthaler"),
  require("./playerProfiles/players/frida-maanum"),
  require("./playerProfiles/players/júlia-bartel"),
  require("./playerProfiles/players/sveindís-jónsdóttir"),
  require("./playerProfiles/players/tove-enblom"),
  require("./playerProfiles/players/marina-hergering"),
  require("./playerProfiles/players/niamh-charles"),
  require("./playerProfiles/players/khadija-shaw"),
  require("./playerProfiles/players/gracie-prior"),
  require("./playerProfiles/players/berna-yeniçeri"),
]