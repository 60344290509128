import "@devexpress/dx-react-grid";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableFixedColumns,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap4";
import React from "react";
import { CompetitionWrapperWSL } from "../../components/layout/CompetionWrapperWSL";
import { wsl } from "../../data/competitions/wsl";
import data from "../../data/wsl/2025/leaderboard";

// Define all possible rounds
const allRounds = Array.from({ length: 22 }, (_, i) => `round-${i + 1}`);

// Get rounds with at least one non-zero score
const activeRounds = allRounds
  .filter(round => data.teams.some(team => team.score[round] > 0))
  .reverse(); // Show latest rounds first

// Define the columns dynamically
const columns = [
  { title: "Rank", name: "rank" },
  { title: "Team Name", name: "teamName" },
  {
    title: "Total",
    name: "total",
    getCellValue: t => t.score["total"] || 0
  },
  ...activeRounds.map(round => ({
    title: `R${round.split("-")[1]}`, // Extract round number
    name: round,
    getCellValue: t => t.score[round] || 0
  }))
];

const FilterCell = props => {
  const { column } = props;
  if (column.name === "teamName") {
    return <TableFilterRow.Cell {...props} />;
  }
  return <th />;
};

// Define column widths
const widths = {
  rank: 80,
  teamName: 300,
  default: 120
};

export const WSLLeaderboard = () => (
  <>
    <CompetitionWrapperWSL competition={wsl}>
      <div className="container clearfix mt-32">
        <h1>ShePlays Global Leaderboard</h1>
        <p>Check out how your team is going against the rest of the ShePlays community on our global leaderboard! </p>
        <p> This season thanks to our friends at <a href="https://www.fantasyfootballhub.co.uk/?via=sheplays" target="_blank">Fantasy Football Hub</a>, each round winner will receive a starter membership. The overall winner will also receive an ultra membership and the top ten will receive a pro membership!</p>
        <a href="https://www.fantasyfootballhub.co.uk/?via=sheplays" target="_blank"> 
          <img className="responsive_large" src="/img/FFH-Banner.jpg" width="100%" />
        </a>
        <p><a href="https://www.fantasyfootballhub.co.uk/?via=sheplays" target="_blank">Fantasy Football Hub</a> is purpose-built to turn FPL managers into FPL winners. From their industry-leading AI to the experts who reveal their teams every gameweek, it's no surprise tens of thousands of managers gain the edge with the Hub every season. <a href="https://www.fantasyfootballhub.co.uk/?via=sheplays" target="_blank">Join the Hub</a> today with 30% off and a 7-day free trial.</p>
      </div>
      <div className="container" style={{ overflowX: 'scroll' }}>
        <Grid
          container
          item
          xs
          rows={data.teams.map((d, i) => ({ ...d, rank: i + 1 }))}
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={50} />
          <SortingState defaultSorting={[]} />
          <FilteringState defaultFilters={[]} />
          <IntegratedSorting />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table columnExtensions={columns.map(({ name }) => ({
            width: widths[name] || widths.default,
            columnName: name
          }))} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel />
        </Grid>
      </div>
      <div className="container">
        <p></p>
      </div>
    </CompetitionWrapperWSL>
  </>
);
export default WSLLeaderboard;

