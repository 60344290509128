import React from "react";
import Flag from "react-world-flags";
import _ from 'lodash';

import moment from "moment";
import { CompetitionWrapperWSL } from "../../components/layout/CompetionWrapperWSL";
import { wsl } from "../../data/competitions/wsl";
import * as numeral from 'numeral';
import data from "../../data/wsl/2025/teamLists/jsondatabase.json";
require('numeral/locales/en-gb');

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const AGE = function(value) {
        const n = moment().diff(moment(moment(value, "DD-MM-YYYY")).format("MM-DD-YYYY"),"years");
        if (Number.isNaN(n)) {
                return '';
        }
        return n;
};


const Image = ({wslteam}) => {
	if (!wslteam.link1){
		return<span></span>
	}
	return (
	      <div>
	      <img
              className="responsive_2col"
              src={`/img/wsl/${wslteam.photo}`}
              width="550"
            />
	       <figcaption align="center">
            Photo by <a href={wslteam.link1} >{wslteam.cap1}</a>, <a href={wslteam.link2} title="Creative Commons">{wslteam.cap2}</a>
            </figcaption>
		<p> </p>
	    </div>
	    )

};

const WSLTeam = ({ wslteam }) => (
  <CompetitionWrapperWSL competition={wsl}>
    <div className="container clearfix mt-2">
      <div className="float-left">
        <h1>{wslteam.teamName}</h1>
        <h5>{wslteam.stadium} <br/>{wslteam.city} </h5>
      </div>
      <div className="container">
        <p />
      </div>
      <img
        className="rounded mx-auto d-block mb-3"
        align="right"
        src={wslteam.badge}
        height={150}
      />
      <div className="float-right mr-2">
        <h3 />
        <h3 />
      </div>
    </div>
    <div className="container">
        {Image({wslteam})}
      <p style={{textAlign:'justify'}}>{wslteam.teamBio}</p>
      <p> Coach: {wslteam.coach} <br/>
      Profile by {wslteam.bioAuthor}</p>
      <p><b>Click on a players name to view their profile page!</b></p>
    </div>

    <div className="container">
      <p />
	<h5 align="right" >- - - - - - - Fantasy Points - - - - - - -</h5>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col" align="center">
              Position
            </th>
            <th scope="col" align="center">
              Nationality
            </th>
            <th scope="col" align="center">Age</th>
            <th scope="col" align="center">Price</th>
            <th scope="col" align="center">19/20</th>
	    <th scope="col" align="center">20/21</th>
	    <th scope="col" align="center">21/22</th>
	    <th scope="col" align="center">22/23</th>
	    <th scope="col" align="center">23/24</th>
            <th scope="col" align="center">24/25</th>
          </tr>
        </thead>
        <tbody>
          {wslteam.Players.gks.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
	      Points20,
	      Points21,
	      Points22,
              Points23,
	      Points,
              DOB,
	      Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/wsl/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
                <td>{Points19}</td>
		<td>{Points20}</td>
		<td>{Points21}</td>
		<td>{Points22}</td>
                <td>{Points23}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wslteam.Players.def.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
	      Points21,
	      Points22,
              Points23,
              Points,
              DOB,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/wsl/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
                <td>{Points19}</td>
                <td>{Points20}</td>
		<td>{Points21}</td>
		    <td>{Points22}</td>
                    <td>{Points23}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wslteam.Players.mid.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
	      Points21,
	      Points22,
              Points23,
              Points,
              DOB,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/wsl/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
                <td>{Points19}</td>
                <td>{Points20}</td>
		<td>{Points21}</td>
		    <td>{Points22}</td>
                    <td>{Points23}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
          {wslteam.Players.fwd.map(
            ({
              Name,
              Position,
              Nationality,
              Club,
              Price,
              Points19,
              Points20,
	      Points21,
              Points22,
              Points23,
              Points,
              DOB,
              Link,
              Number
            }) => (
              <tr key={Name}>
                <td>{Number}</td>
                <td><a href={`/wsl/players/${_.kebabCase(Link)}`}>{Name}</a></td>
                <td>{Position}</td>
                <td><img hspace="20" src={`/img/RoundFlags/${Nationality}.png`} width="40"/></td>
                <td>
		    {AGE(DOB)}
                </td>
                <td>{numeral(Price).format()}</td>
                <td>{Points19}</td>
                <td>{Points20}</td>
		<td>{Points21}</td>
		    <td>{Points22}</td>
                    <td>{Points23}</td>
		<td><b>{Points}</b></td>
              </tr>
            )
          )}
        </tbody>
	<thead className="thead-dark">
	<tr>
        <th scope="col" colspan="11">Team's Total Points:</th>
	<th scope="col">{wslteam.Score}</th>
	</tr>
        </thead>
      </table>
    </div>

    <div className="container">
      <p />
    </div>
  </CompetitionWrapperWSL>
);

export default WSLTeam;
