import React, { useState } from "react";
import { useAuth } from "../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import Carousel from 'react-bootstrap/Carousel'
import moment from "moment";
import BottomNavBar from '../components/layout/MCompetionWrapperHome.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";


const localDate = dateString => moment(dateString).format("Do MMMM, h:mm A");

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};


const LandingPageD = () => (
  <>
    <head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
    </head>

    <header className="masthead text-center text-white">
      <div className="masthead-content">
        <div className="container">

                  <h1 className="masthead-heading mb-0">Welcome to ShePlays!</h1>
          <p className="mb-0">
            ShePlays Incorporated run fantasy football competitions, aiming to help promote women's
            sport in Australia and around the world.
            Wherever you're from, and whichever team you support, you're welcome
            at ShePlays!
          </p>
          <a
            href="/how-to-play"
            className="btn btn-secondary btn-xl rounded-pill mt-5"
          >
            Learn More
          </a>
        </div>
      </div>
    </header>


    <section>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <img
                className="img-fluid rounded-circle"
                src="/img/uwcl/uwcl3.png"
                alt=""
              />
<figcaption align="center"><a href="https://www.flickr.com/photos/gotbphotography/albums/72177720308311968/with/52901114073/" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>
            </div>
          </div>
          <div className="col-lg-6 order-lg-2">
            <div className="p-5">
              <h2 className="display-4">ShePlays UWCL</h2>
              <p style={{textAlign:'justify'}}> Our inaugural ShePlays UWCL competition is here, with team selection for 2024/25 now open!
              The deadline to get your team in for round 1 is {localDate("2024-10-08 15:45:00+00")}. </p>

              <p style={{textAlign:'justify'}}> Create a free account, then start picking the 15 players that will make up your UEFA Women's Champions League dream team. We have over
              400 players from the best teams in Europe to chose from, but you'll need to stay within your initial budget of $8,000,000. Each week you can chose your starting XI, pick your captain, 
              or make player transfers. You can also compete in any of our public mini-leagues, or create a private one for you and your friends.

</p>
                     <div className="centerBox">
              <a
                href="/uwcl"
                align="center"
                className="btn btn-wleague btn-xl rounded-pill mt-1"
              >
                ShePlays UWCL 
              </a>
        </div>

            </div>
          </div>
        </div>
      </div>
    </section>




    <section>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-2">
            <div className="p-5">
              <img
                className="img-fluid rounded-circle"
                src="/img/GOTBwsl2.png"
                alt=""
              />
<figcaption align="center"><a href="https://www.flickr.com/photos/gotbphotography/albums/72177720308311968/with/52901114073/" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All rights reserved</figcaption>
            </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlays WSL</h2>
              <p style={{textAlign:'justify'}}> Our ShePlaysWSL competition is back for our 6th season, with team selection for 2024/25 now open! 
              The deadline to get your team in for round 1 is {localDate("2024-09-20 17:00:00+00")}. </p>

              <p style={{textAlign:'justify'}}> Create a free account, then start picking the 15 players that will make up your WSL dream team. We have over 
              280 WSL players to chose from, but you'll need to stay within your budget of $6,000,000. Each week you can chose your starting XI, pick your captain, 
              or make player transfers. You can also compete in any of our public mini-leagues, or create a private one for you and your friends.

</p>
                     <div className="centerBox">
              <a
                href="/wsl"
                align="center"
                className="btn btn-secondary btn-xl rounded-pill mt-1"
              >
                ShePlays WSL
              </a>
        </div>

            </div>
          </div>
        </div>
      </div>
    </section>



   <section>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <img
                className="img-fluid rounded-circle"
                src="/img/nwslRodman.png"
                alt=""
              />
	<figcaption align="center">Peyton Skeels<br/>All Rights Reserved</figcaption>
            </div>
          </div>
          <div className="col-lg-6 order-lg-2">
            <div className="p-5">
              <h2 className="display-4">ShePlays NWSL</h2>
              <p style={{textAlign:'justify'}}>
              Our ShePlaysNWSL competition is back for the 2025 season and team selection is now open! </p> 

	       <p style={{textAlign:'justify'}}>
	       Create a free account, then start picking the 15 players that will make up your NWSL dream team. We have over
              300 NWSL players to chose from, but you'll need to stay within your budget of $3,500,000. Each week you can chose your starting XI, pick your captain, 
              or make player transfers. You can also compete in any of our public mini-leagues, or create a private one for you and your friends.
              </p>

             <div className="centerBox">
              <a
                href="/nwsl"
                align="center"
                className="btn btn-nwsl btn-xl rounded-pill mt-1"
              >
                ShePlays NWSL
              </a>
             </div>

            </div>
          </div>
        </div>
      </div>
    </section>


  <section>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-lg-2">
          <div className="p-5">
            <img className="img-fluid rounded-circle" src="img/wwc/fra3.png" alt="" />
	<figcaption align="center"><a href="https://www.girlsontheball.com/" title = "GirlsontheBall" target="blank">GirlsontheBall</a><br/>All Rights Reserved</figcaption>
      </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlays World Cup</h2>
              <p style={{textAlign:'justify'}}>
               The FIFA Women's World Cup is back, with the 2023 tournament being hosted down here in Australia and New Zealand. Our ShePlays
               fantasy World Cup competition is also back for 2023, with team selection opening soon! This is your chance to build your
               dream team from players competing at the 2023 World Cup, and to test yourself against your friends, family, and the rest of
               the ShePlays community. Our fantasy competition is a great way to learn more about the players and teams competing in the tournament,
               and to make sure you don't miss out on any of the action.
              </p>
            </div>
          </div>
        </div>
        <div className="leftBox">
        <p style={{textAlign:'justify'}}>
                <b> Our fantasy World Cup team selection is now open!</b> The deadline to get your team in
                for round 1 is {localDate("2023-07-20 17:00+10")}.
                 Make sure you follow us on
                <a href="https://twitter.com/sheplays_au" target="_blank">
                  {" "}
                  Twitter
                </a>
                ,{" "}
                <a
                  href="https://www.instagram.com/sheplays_au/"
                  target="_blank"
                >
                  {" "}
                  Instagram
                </a>{" "}
                and{" "}
                <a
                  href="https://www.facebook.com/sheplaysfantasyfootball/"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                to keep up to date with the latest news and announcements.


              </p>


        </div>
                <div className="centerBox">
              <a
                href="/wwc"
                align="center"
                className="btn btn-wwc btn-xl rounded-pill mt-1"
              >
                ShePlays World Cup
              </a>
        </div>

      </div>
    </section>

    <section>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-2">
            <div className="p-5">
              <img
                className="img-fluid rounded-circle"
                src="/img/wl/wl-1.png"
                alt=""
              />
	<figcaption align="center"><a href="https://www.instagram.com/klzphotography/" title = "Kellie Lemon" target="blank"> Kellie Lemon (Instagram - @klzphotography)</a><br/>All rights reserved</figcaption>
            </div>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">ShePlays Archive</h2>
             <p style={{textAlign:'justify'}}> 
	     Our ShePlays Archive is where you can browse all of the statistics, fantasy points, and player profiles from any of our
	     previous ShePlays fantasy competitions. This includes our W-League, AFLW, Euros, and 2019 World Cup leagues. We've been running women's fantasy football
              since 2016, so we have plenty of data available for you to dive through!
	     </p>
            </div>
          </div>
        </div>
        <div className="centerBox">
              <a
                href="/arc"
                className="btn btn-wleague btn-xl rounded-pill mt-1"
              >
                ShePlays Archive
              </a>
        </div>
      </div>
    </section>


    <div className="container" align="center">
      <h3>Support ShePlays</h3>
      <p>
        Like what we do? Want to help us get bigger and better? Click on the
        button below to donate!
        <br />
        <a
          href="https://ko-fi.com/sheplays"
          target="_blank"
          className="btn btn-kofi btn-s mt-1"
        >
          <img src="/img/Ko-fi1.png" width="200" />
        </a>
        <br />
        Want to support us with your time instead of your money? Check out how
        you can be a part of the ShePlays team <a href="/join-us">here!</a>
      </p>
    </div>

    <div className="container">
      <p align="center">
        <a
          href="https://www.facebook.com/sheplaysfantasyfootball/"
          target="_blank"
          className="fa fa-facebook"
          style={{ color: "white" }}
        ></a>
        <a
          href="https://twitter.com/sheplays_au"
          target="_blank"
          className="fa fa-twitter"
          style={{ color: "white" }}
        ></a>
      </p>
    </div>
  </>
);

const LandingPageM = () => (
  <>
    <head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
    </head>


<Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100 bg-image"
      src='/img/home-dark.png'
      alt="First slide"
    />
    <Carousel.Caption style={{backgroundColor:`linearGradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2))`}}>
      <h3>Welcome to ShePlays!</h3>
      <p style={{fontSize:'0.8rem'}}>ShePlays is a fantasy football competition, aiming to help promote women's sport in Australia and around the world.</p>
	<a
            href="/how-to-play"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            Learn More
          </a>

    </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="d-block w-100"
	src='/img/uwcl/uwcldark.png'
      alt="Second slide"
    />


    <Carousel.Caption>
      <h3>ShePlays UWCL</h3>
      <p style={{fontSize:'0.8rem'}}>Compete against your friends in our fantasy UEFA Women's Champions League competition.</p>
                <a
            href="/uwcl"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays UWCL
          </a>
    </Carousel.Caption>
  </Carousel.Item>


  <Carousel.Item>
    <img
      className="d-block w-100"
	src='/img/wsl-dark.png'
      alt="Second slide"
    />


    <Carousel.Caption>
      <h3>ShePlays WSL</h3>
      <p style={{fontSize:'0.8rem'}}>Compete against your friends in our fantasy WSL competition.</p>
                <a
            href="/wsl"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays WSL
          </a>
    </Carousel.Caption>
  </Carousel.Item>



	
  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/nwsl/nwsl-dark.png'
      alt="Second slide"
    />
    <Carousel.Caption>
      <h3>ShePlays NWSL</h3>
      <p style={{fontSize:'0.8rem'}}>Compete against your friends in our fantasy NWSL competition.</p>
                <a
            href="/nwsl"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays NWSL
          </a>
    </Carousel.Caption>
  </Carousel.Item>

  <Carousel.Item>
    <img
      className="d-block w-100"
        src='/img/wwc/england-dark2.png'
      alt="Second slide"
    />


    <Carousel.Caption>
      <h3>ShePlays World Cup</h3>
      <p style={{fontSize:'0.8rem'}}>Compete against your friends in our fantasy World Cup competition.</p>
                <a
            href="/wwc"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays World Cup
          </a>
    </Carousel.Caption>
  </Carousel.Item>


  <Carousel.Item>
    <img
      className="d-block w-100"
      src='/img/alw-dark.png'
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>ShePlays Archive</h3>
	<p style={{fontSize:'0.8rem'}}>Browse the statistics,fantasy points, and player profiles from any of our previous leagues, 
	including the W-League, AFLW, 2022 Euros, and 2019 World Cup!</p>
	        <a
            href="/arc"
            className="btn btn-secondary btn-c rounded-pill mt-1"
          >
            ShePlays Archive
          </a>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>

	<div className="container">
     <a href='/how-to-play' className="btn btn-teams btn-l rounded-pill mt-1" >
        How To Play
        </a>
	     <a href='/about' className="btn btn-teams btn-l rounded-pill mt-1" >
        About ShePlays
        </a>
     <a href='/join-us' className="btn btn-teams btn-l rounded-pill mt-1" >
        Join Our Team
        </a>
<a href="https://ko-fi.com/sheplays" target="_blank" className="btn btn-teams btn-l rounded-pill mt-1" >
        Support ShePlays on Ko-fi &nbsp; <SiKoFi/>
        </a>

	</div>
        <div className="container">
            <p align="center" style={{marginTop:'0.5cm'}}><a href="https://www.facebook.com/sheplaysfantasyfootball/" className="fa fa-facebook" style={{ color: 'white'}}></a>
            <a href="https://twitter.com/sheplays_au" className="fa fa-twitter" style={{ color: 'white', marginLeft:'0.2cm', marginRight:'0.2cm'}}></a>
            <a href="https://instagram.com/sheplays_au" className="fa fa-instagram" style={{ color: 'white'}}></a></p>
        </div>
</>
);

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  return width < breakpoint ? <LandingPageM /> : <LandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}


