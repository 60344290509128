import React, { useState } from 'react';
import { useAuth } from "../util/withAuth";
import { Auth, Hub } from "aws-amplify";
import moment from 'moment';
import { CompetitionWrapperNWSL } from '../components/layout/CompetionWrapperNWSL';
import { nwsl } from '../data/competitions/nwsl';
import BottomNavBar from '../components/layout/MCompetionWrapperNWSL.jsx';
import { SiKoFi } from "react-icons/si";
import { AiOutlineInstagram} from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};

const SPLandingPageD = () => (
<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>

    <div className="container">
        <h1>ShePlays Admin Index</h1>
        <h5>This page is for members of the ShePlays volunteer team ONLY. Do not share these links with people 
        outside of our team.</h5>
        <p>Below are links to edit and update player profiles, team profiles, and competition fixtures, for the NWSL, WSL, and UWCL. 
Please be careful making changes, as any edits will become live on the site the next time points are updated.
</p><p><b>Thank you for your help!</b>
</p>
</div>


<section>
  <div className="p-5">
    <h3 className="display-5">ShePlays NWSL</h3>

    {/* Player Profiles */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Player Profiles</h5>
        <p>Click on the button below to access the NWSL Player Profiles Budibase database.<br/>
           Do not delete players. Do not change player names or player IDs.</p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/nwslplayers"
          target="_blank"
          className="btn btn-nwsl btn-l rounded-pill btn-fixed-width"
        >
          NWSL Player Profiles
        </a>
      </div>
    </div>

    {/* Team Profiles */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Team Profiles</h5>
        <p>Click on the button below to access the NWSL Team Profiles Budibase database.<br/>
        Do not delete teams. Do not change team names.</p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/nwslteams"
          target="_blank"
          className="btn btn-nwsl btn-l rounded-pill btn-fixed-width"
        >
          NWSL Team Profiles
        </a>
      </div>
    </div>

    {/* Fixtures */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Fixtures</h5>
        <p>Click on the button below to access the NWSL Fixtures Budibase database.</p>
        <p>
          <b>Note:</b> Dates/times MUST be in <b>US EST (New York)</b><br />
          Dates format: <b>Friday, March 14, 2025</b><br />
          Time format: <b>8:00 PM</b>
        </p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/nwslfixtures"
          target="_blank"
          className="btn btn-nwsl btn-l rounded-pill btn-fixed-width"
        >
          NWSL Fixtures
        </a>
      </div>
    </div>

  </div>
</section>





<section>
  <div className="p-5">
    <h3 className="display-5">ShePlays WSL</h3>

    {/* Player Profiles */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Player Profiles</h5>
        <p>Click on the button below to access the WSL Player Profiles Budibase database.<br/>
           Do not delete players. Do not change player names or player IDs.</p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/wslplayers"
          target="_blank"
          className="btn btn-secondary btn-l rounded-pill btn-fixed-width"
        >
          WSL Player Profiles
        </a>
      </div>
    </div>

    {/* Team Profiles */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Team Profiles</h5>
        <p>Click on the button below to access the WSL Team Profiles Budibase database.<br/>
        Do not delete teams. Do not change team names.</p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/wslteams"
          target="_blank"
          className="btn btn-secondary btn-l rounded-pill btn-fixed-width"
        >
          WSL Team Profiles
        </a>
      </div>
    </div>

    {/* Fixtures */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Fixtures</h5>
        <p>Click on the button below to access the WSL Fixtures Budibase database.</p>
        <p>
          <b>Note:</b> Dates/times MUST be in <b>UK GMT (London)</b><br />
          Dates format: <b>Friday, 20 September 2024</b><br />
          Time format: <b>6:00 PM</b>
        </p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/wslfixtures"
          target="_blank"
          className="btn btn-secondary btn-l rounded-pill btn-fixed-width"
        >
          WSL Fixtures
        </a>
      </div>
    </div>

  </div>
</section>





<section>
  <div className="p-5">
    <h3 className="display-5">ShePlays UWCL</h3>

    {/* Player Profiles */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Player Profiles</h5>
        <p>Click on the button below to access the UWCL Player Profiles Budibase database.<br/>
           Do not delete players. Do not change player names or player IDs.</p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/uwclplayers"
          target="_blank"
          className="btn btn-wleague btn-l rounded-pill btn-fixed-width"
        >
          UWCL Player Profiles
        </a>
      </div>
    </div>

    {/* Team Profiles */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Team Profiles</h5>
        <p>Click on the button below to access the UWCL Team Profiles Budibase database.<br/>
        Do not delete teams. Do not change team names.</p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/uwclteams"
          target="_blank"
          className="btn btn-wleague btn-l rounded-pill btn-fixed-width"
        >
          UWCL Team Profiles
        </a>
      </div>
    </div>

    {/* Fixtures */}
    <div className="row my-4">
      <div className="col-md-8">
        <h5>Fixtures</h5>
        <p>Click on the button below to access the UWCL Fixtures Budibase database.</p>
        <p>
          <b>Note:</b> Dates/times MUST be in <b>CET (Berlin)</b><br />
          Dates format: <b>Tuesday,8 October 2024</b><br />
          Time format: <b>8:00 PM</b>
        </p>
      </div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/uwclfixtures"
          target="_blank"
          className="btn btn-wleague btn-l rounded-pill btn-fixed-width"
        >
          UWCL Fixtures
        </a>
      </div>
    </div>

  </div>
</section>
</>

);











const SPLandingPageM = () => {
        const currentUser = useAuth()
        return(<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
</head>
    <div className="container">
        <h3>ShePlays Admin Index</h3>
        <p><b>This page is for members of the ShePlays volunteer team ONLY. Do not share these links with people
        outside of our team.</b></p>
        <p style={{fontSize:'0.8rem'}}>Below are links to edit and update player profiles, team profiles, and competition fixtures, for the NWSL, WSL, and UWCL.
Please be careful making changes, as any edits will become live on the site the next time points are updated. 
<b> Thank you for your help!</b>
</p>
</div>

<section>
<h4 style={{textAlign: 'center'}}>ShePlays NWSL</h4>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/nwslplayers"
          target="_blank"
          className="btn btn-nwsl btn-l rounded-pill btn-fixed-width"
        >
          NWSL Player Profiles
        </a>
      </div>
<div style={{height: '10px'}}></div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/nwslteams"
          target="_blank"
          className="btn btn-nwsl btn-l rounded-pill btn-fixed-width"
        >
          NWSL Team Profiles
        </a>
      </div>
<div style={{height: '10px'}}></div>

      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/nwslfixtures"
          target="_blank"
          className="btn btn-nwsl btn-l rounded-pill btn-fixed-width"
        >
          NWSL Fixtures
        </a>
      </div>

        <p style={{fontSize:'0.8rem',paddingLeft: '20px'}}>
          <b>Note:</b> Dates/times MUST be in <b>US EST (New York)</b><br />
          Dates format: <b>Friday, March 14, 2025</b><br />
          Time format: <b>8:00 PM</b>
        </p>
</section>


<section>
<h4 style={{textAlign: 'center'}}>ShePlays WSL</h4>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/wslplayers"
          target="_blank"
          className="btn btn-secondary btn-l rounded-pill btn-fixed-width"
        >
          WSL Player Profiles
        </a>
      </div>
<div style={{height: '10px'}}></div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/wslteams"
          target="_blank"
          className="btn btn-secondary btn-l rounded-pill btn-fixed-width"
        >
          WSL Team Profiles
        </a>
      </div>
<div style={{height: '10px'}}></div>

      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/wslfixtures"
          target="_blank"
          className="btn btn-secondary btn-l rounded-pill btn-fixed-width"
        >
          WSL Fixtures
        </a>
      </div>

        <p style={{fontSize:'0.8rem',paddingLeft: '20px'}}>
          <b>Note:</b> Dates/times MUST be in <b>UK GMT (London)</b><br />
          Dates format: <b>Friday, 20 September 2024</b><br />
          Time format: <b>6:00 PM</b>
        </p>
</section>

<section>
<h4 style={{textAlign: 'center'}}>ShePlays UWCL</h4>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/uwclplayers"
          target="_blank"
          className="btn btn-wleague btn-l rounded-pill btn-fixed-width"
        >
          UWCL Player Profiles
        </a>
      </div>
<div style={{height: '10px'}}></div>
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/uwclteams"
          target="_blank"
          className="btn btn-wleague btn-l rounded-pill btn-fixed-width"
        >
          UWCL Team Profiles
        </a>
      </div>
<div style={{height: '10px'}}></div>

      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <a
          href="https://sheplays.budibase.app/app/uwclfixtures"
          target="_blank"
          className="btn btn-wleague btn-l rounded-pill btn-fixed-width"
        >
          UWCL Fixtures
        </a>
      </div>

        <p style={{fontSize:'0.8rem',paddingLeft: '20px'}}>
          <b>Note:</b> Dates/times MUST be in <b>CET (Berlin)</b><br />
          Dates format: <b>Tuesday,8 October 2024</b><br />
          Time format: <b>8:00 PM</b>
        </p>
</section>

        <BottomNavBar />
   </>  );
};

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 1000;

  return width < breakpoint ? <SPLandingPageM /> : <SPLandingPageD />;
};


export default function App() {
        const currentUser = useAuth()
  return (
    <ViewportProvider>
      <MyComponent />
    </ViewportProvider>
  );
}

