import "@devexpress/dx-react-grid";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableFixedColumns,
  TableHeaderRow
} from "@devexpress/dx-react-grid-bootstrap4";
import React from "react";
import { CompetitionWrapperUWCL } from "../../components/layout/CompetionWrapperUWCL";
import BottomNavBar from "../../components/layout/MCompetionWrapperUWCL.jsx";
import { uwcl } from "../../data/competitions/uwcl";
import data from "../../data/uwcl/2025/leaderboard";

// Define all possible rounds
const allRounds = Array.from({ length: 25 }, (_, i) => `round-${i + 1}`);

// Get rounds with at least one non-zero score
const activeRounds = allRounds
  .filter(round => data.teams.some(team => team.score[round] > 0))
  .reverse(); // Show latest rounds first

// Define the columns dynamically
const columns = [
  { title: "Rank", name: "rank" },
  { title: "Team Name", name: "teamName" },
  {
    title: "Total",
    name: "total",
    getCellValue: t => t.score["total"] || 0
  },
  ...activeRounds.map(round => ({
    title: `R${round.split("-")[1]}`, // Extract round number
    name: round,
    getCellValue: t => t.score[round] || 0
  }))
];

const FilterCell = props => {
  const { column } = props;
  if (column.name === "teamName") {
    return <TableFilterRow.Cell {...props} />;
  }
  return <th />;
};

const widths = {
  rank: 80,
  teamName: 300,
  default: 120
}

export const UWCLLeaderboard = () => (
  <>
      <div className="container clearfix mt-32">
      </div>
      <div className="container" style={{overflowX: 'scroll',fontSize:'0.8rem'}}>
        <Grid container item xs 
          rows={data.teams.map((d, i) => ({ ...d, rank: i + 1 }))}
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={50} />

          <SortingState defaultSorting={[]} />
          <FilteringState defaultFilters={[]} />
          <IntegratedSorting />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table columnExtensions={columns.map(({name}) => ({
            width: widths[name] || widths.default,
            columnName: name
          }))}/>
          <TableHeaderRow showSortingControls />
          <TableFilterRow cellComponent={FilterCell} />
          <PagingPanel />
        </Grid>
      </div>
      <div className="container">
        <p></p>
      </div>
	<BottomNavBar/>
  </>
);
export default UWCLLeaderboard;
